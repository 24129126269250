<template>
	<div class="container" v-title :data-title="menuName">
    <div class="breadcrumbs-box">
      <div class="breadcrumbs">
        <i class="el-icon-s-home home-icon"></i>
        <div class="item">
          <router-link to="/">首页</router-link>
        </div>
        <i class="el-icon-arrow-right right-icon"></i>
        <div class="item">
          <div>{{ childMenuName?childMenuName:menuName }}</div>
        </div>
      </div>
    </div>
    <div class="container-box">
      <div class="news-list">
        <div class="title">
          {{ childMenuName?childMenuName:menuName }}
        </div>
        <ul class="news-box" v-if="newsList&&newsList.length>0">
          <li class="news-item" v-for="item in newsList" :key="item.newsNumber">
            <router-link target="_blank" :to="'/newsdetails?newsNumber='+item.newsNumber">
              <span class="item-title">{{ item.title }}</span>
              <span class="item-time">{{ item.createTime.split(' ')[0] }}</span>
            </router-link>
          </li>
          
        </ul>
        <div class="news-box" v-else>
          <div class="no-data">暂无数据</div>
        </div>
        <div class="page-box">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="size"
            layout="prev, pager, next, total, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
      <div class="other-news-list">
        <div class="title">
          推荐新闻
          <div>
            <i class="el-icon-arrow-right right-icon"></i>
            <i class="el-icon-arrow-right right-icon"></i>
          </div>
        </div>
        <ul class="news-box">
          <li class="news-item" v-for="item in newsImportantList" :key="item.newsNumber">
            <router-link target="_blank" :to="'/newsdetails?newsNumber='+item.newsNumber" :title="item.title">
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { pageNewsList,getNewsImportantList } from '../../network/news'


export default {
  name: "NewsList",
  data () {
    return {
      newsList: [],
      menuId: 0,
      page: 1,
      size: 10,
      total: 0,
      title: '',
      menuName: '',
      childMenuName: '',
      newsImportantList: []
    };
  },
  props: {
    
  },
  components: {
    
  },
  mounted() {
    this.getNewsImportantList()

    const params = this.$route.query;
    if(params && params.menuId) {
      this.menuId = Number(params.menuId)
      this.pageNewsList(this.menuId,this.page,this.size,this.title)
    }
    if(params && params.menuName) {
      this.menuName = params.menuName
      document.title = this.menuName
    }
    if(params && params.menuName) {
      this.childMenuName = params.childMenuName
    }
  },
  beforeRouteUpdate (to, from, next) {
    // to：目标路由对象
    // from：原路由对象
    // next：可指定路由进行跳转
    next();
    this.menuId = Number(to.query.menuId)
    this.menuName = to.query.menuName
    this.childMenuName = to.query.childMenuName?to.query.childMenuName:''
    document.title = this.menuName
    this.pageNewsList(this.menuId,this.page,this.size,this.title)
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val
      this.pageNewsList(this.menuId,this.page,this.size,this.title)
    },
    // 网络请求
    pageNewsList(menuId,page,size,title) {
      pageNewsList(menuId,page,size,title).then(res=>{
        if(res.status===200&&res.data) {
          this.newsList = res.data
          this.total = res.total
        }
      })
    },
    getNewsImportantList() {
      getNewsImportantList().then(res=>{
        if(res.status===200&&res.data) {
          this.newsImportantList = res.data
          console.log('推荐新闻列表',this.newsList)
        }
      })
    },
  }
}
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  .breadcrumbs-box {
    width: 100%;
    height: 40px;
    background-color: #eee;
    .breadcrumbs {
      width: 1200px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .home-icon {
        color: var(--theme-text-color);
        font-size: 14px;
      }
      .right {
        color: #535353;
        font-size: 14px;
      }
      .item {
        margin-left: 10px;
        font-size: 14px;
        color: #535353;
        cursor: pointer;
      }
    }
  }
  .container-box {
    width: 1200px;
    margin: 20px auto 35px;
    display: flex;
    justify-content: space-between;
    .news-list {
      width: 870px;
      .title {
        width: 100%;
        font-size: 18px;
        color: #313131;
        font-weight: bold;
        border-bottom: 1px solid #7d7d7d;
        padding: 10px 0;
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          width: 74px;
          height: 6px;
          background-color: var(--theme-color);
          position: absolute;
          left: 0;
          bottom: -3px;
        }
      }
      .news-box {
        padding-top: 12px;
        .no-data {
          color: #999;
          font-size: 14px;
          text-align: center;
          padding: 10px 0;
        }
        .news-item {
          padding: 0 20px;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px dashed #d2d2d2;
          list-style-type: none;
          padding-left: 20px;
          color: #1b1b1b;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          display: flex;

          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: #1b1b1b;
            rotate: 45deg;
            margin-right: 10px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            display: flex;
            .item-title {
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .time {
              width: 80px;
              text-align: right;
            }
          }
          &:hover {
            a {
              color: var(--theme-color);
            }
          }
        }
      }
      .page-box {
        padding-top: 60px;
        text-align: center;
      }
      .page-box {
        padding-top: 60px;
        text-align: center;
      }
    }
    .other-news-list {
      width: 310px;
      .title {
        border-top: 10px solid var(--theme-color);
        background-color: #eee;
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 34px;
        font-size: 20px;
        font-weight: bold;
        color: var(--theme-text-color);
        .right-icon {
          color: var(--theme-text-color);
        }
      }
      .news-box {
        border: 1px solid #e5e5e5;
        padding: 10px 20px;
        .news-item {
          padding: 0 20px;
          height: 35px;
          line-height: 35px;
          list-style-type: none;
          padding-left: 20px;
          color: #1b1b1b;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: var(--theme-color);
            rotate: 45deg;
            margin-right: 10px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
          
          &:hover {
            a {
              color: var(--theme-color);
            }
          }
        }
      }
    }
  }
}
</style>
