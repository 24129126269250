import {request} from './request'
import baseUrlObj from "./baseURL"
const systemNumber = baseUrlObj.systemNumber

// 获取新闻列表 /ewi/guest/News/PageNewsList
export function pageNewsList(menuId,page,size,title){
  return request({
    url: '/ewi/guest/News/PageNewsList',
    method: 'post',
    data: {
      menuId,
      page,
      size,
      title,
      systemNumber: systemNumber,
    }
  })
}

// 获取单个新闻的所有信息 /ewi/guest/News/GetNewsAllInfo
export function getNewsAllInfo(newsNumber){
  return request({
    url: '/ewi/guest/News/GetNewsAllInfo',
    method: 'get',
    params: {
      newsNumber,
      systemNumber: systemNumber,
    }
  })
}

// 通过栏目编号获取单个新闻的所有信息(单页内容专用) /ewi/guest/News/GetNewsAllInfoByMenuId
export function getNewsAllInfoByMenuId(menuId){
  return request({
    url: '/ewi/guest/News/GetNewsAllInfoByMenuId',
    method: 'get',
    params: {
      menuId,
      systemNumber: systemNumber,
    }
  })
}

// 获取推荐新闻列表(缓存) /ewi/guest/News/GetNewsImportantList
export function getNewsImportantList(){
  return request({
    url: '/ewi/guest/News/GetNewsImportantList',
    method: 'get',
    params: {
      systemNumber: systemNumber,
    }
  })
}
